import React, { useState, useEffect, useRef } from 'react';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { CtaCard } from '../General/CtaCard';
import { BodyFeatureCard } from '../Industry/Features/BodyFeatureCard';
import { BodyFeature } from '../Industry/Features/BodyFeature';
import { Testimonials } from '../Testimonials';
import { MobileFeatures } from '../Industry/Features/Mobile/MobileFeatures';
import { CustomerLogos } from '../CustomerLogos';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { TemplatesIntro } from '../Templates/TemplatesIntro';
import { Related } from '../Industry/Related';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { IndustryFAQ } from '../Industry/IndustryFAQ';

const TestimonialCarousel = loadable(() =>
  import('../../components/Testimonials/TestimonialCarousel')
);

const useStyles = makeStyles((theme) => ({
  testimonialBackground: {
    backgroundSize: 'cover',
    height: '600px',
    width: '99vw',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'left 60px',
    },
  },
  testimonialContainer: {
    marginTop: '-40rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-30rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-25rem',
    },
  },
  testimonialTitle: {
    color: theme.white,
    fontWeight: 700,
    padding: '2rem 0',
  },
  relatedBackground: {
    marginBottom: '7rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '4rem',
    },
  },
  formWaveDown: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-3rem',
    },
  },
  formWaveUp: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10rem',
    },
  },
}));

export const TemplatesBody = ({
  template,
  iosLogo,
  androidLogo,
  qbLogo,
  setFeatureModalOpen,
}) => {
  const classes = useStyles();

  const formRef = useRef();
  const featureRef = useRef(null);

  //card array that gets modified on click. set brand new array to be manipulated on mount
  const [cardArray, setCardArray] = useState([]);

  useEffect(() => {
    setCardArray(template.featureSection);
  }, []);

  const setToFirst = (selected) => {
    const filteredArray = cardArray.filter(
      (item) => item._key !== selected._key
    );
    setCardArray([selected, ...filteredArray]);
  };

  //handles scrolling to selected feature => currently pointed at the div above the cards on desktop view
  const handleSelectFeature = async (e, content) => {
    await setSelectedFeature(content._key);
    setToFirst(content);
    featureRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  //state for selected card for features
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    setSelectedFeature(template.featureSection[0]?._key);
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleFormSubmission = (event) => {
      if (
        event.origin !== 'https://go.realgreen.com' ||
        event.data !== 'pardotFormSubmitted'
      ) {
        return;
      }

      if (isProcessing) {
        return;
      }

      setIsProcessing(true);

      // console.log('Pardot form submitted message received');

      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
          event: 'pardotFormSubmitted',
          eventCategory: 'Forms',
          eventAction: 'submit',
          eventLabel: 'Pardot Form',
        });
        // console.log('Event pushed to dataLayer:', {
        //   event: 'pardotFormSubmitted',
        //   eventCategory: 'Forms',
        //   eventAction: 'submit',
        //   eventLabel: 'Pardot Form',
        // });
      }

      setTimeout(() => setIsProcessing(false), 1000);
    };

    window.addEventListener('message', handleFormSubmission);

    return () => {
      window.removeEventListener('message', handleFormSubmission);
    };
  }, [isProcessing]);

  const med = useMediaQuery('(max-width: 960px)');
  const lg = useMediaQuery('(max-width: 1280px)');

  const {
    _rawOverviewBody,
    callout,
    featureSection,
    featureHeader,
    accentColor,
    softwareFeatures,
    testimonial,
    customerLogos,
    customerLogosHeader,
    testimonialTitle,
    testimonialBackground,
    relatedTemplates,
    faq,
    faqTitle,
    relatedHeader,
  } = template;

  const firstSet = cardArray.length === 4 ? 2 : 3;
  const lastSet =
    cardArray.length === 4
      ? -2
      : cardArray.length === 6
      ? -3
      : cardArray.length === 5
      ? -2
      : null;

  const longPage = template.featureSection.length > 2;

  return (
    <>
      <Container>
        <TemplatesIntro intro={_rawOverviewBody} />

        {med ? (
          <MobileFeatures
            featureSection={featureSection}
            featureHeader={featureHeader}
            featureRef={featureRef}
            selectedFeature={selectedFeature}
            accentColor={accentColor[0].hexValue ?? '#002D5C'}
            iosLogo={iosLogo}
            androidLogo={androidLogo}
            qbLogo={qbLogo}
            featureListCta={softwareFeatures?.featureListCta}
            setFeatureModalOpen={setFeatureModalOpen}
          />
        ) : (
          longPage && (
            <BodyFeatureCard
              featureHeader={featureHeader}
              featureSection={featureSection}
              selectedFeature={selectedFeature}
              handleClick={handleSelectFeature}
              featureRef={featureRef}
              featureListCta={softwareFeatures?.featureListCta}
              setFeatureModalOpen={setFeatureModalOpen}
              accentColor={accentColor[0].hexValue ?? '#002D5C'}
            />
          )
        )}
      </Container>

      <Container>
        {!med &&
          cardArray
            .slice(0, firstSet)
            .map((feature, index) => (
              <BodyFeature
                feature={feature}
                index={index}
                key={index}
                selectedFeature={selectedFeature}
                accentColor={accentColor?.hexValue ?? '#002D5C'}
                iosLogo={iosLogo}
                androidLogo={androidLogo}
                qbLogo={qbLogo}
                setSelectedFeature={setSelectedFeature}
              />
            ))}
        <div
          className={classes.relatedBackground}
          style={{ background: '#FFFFFF' }}
        >
          <Container>
            <Related
              related={relatedTemplates}
              header={relatedHeader}
              templates
            />
          </Container>
        </div>

        <CtaCard
          background={callout.ctaBackground}
          header={callout.ctaHeader}
          subheader={callout.ctaSubheader}
          ctaText={callout.ctaText}
          internalLink={callout.internalLink}
          ctaLink={callout.ctaLink}
        />
      </Container>
      <WaveDownSVG height="213" width="100%" fill="#FFFFFF" />
      <div style={{ background: '#f4f8ff' }}>
        <Container>
          <IndustryFAQ header={faqTitle} faq={faq} />
        </Container>
      </div>
      <WaveUpSVG height="213" width="100%" fill="#fff" />
      {/* should change them all to work this way. pass the background prop to render white header instead of workwave blue */}
      <div
        className={classes.testimonialBackground}
        style={{
          backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
        }}
      />
      <WaveUpSVG fill="#FFF" />
      <div className={classes.testimonialContainer}>
        <Testimonials
          header={testimonialTitle}
          testimonials={testimonial}
          formRef={formRef}
        />
      </div>
      <Container style={{ marginBottom: '8rem' }}>
        <CustomerLogos
          customerLogosArray={customerLogos}
          customerLogosHeader={customerLogosHeader}
        />
      </Container>
    </>
  );
};
